.photogallery {
    background-color: rgb(206, 222, 236);
    padding: 100px 35px;
}
.pg-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1.0;
    background-size: cover;
    z-index: -1;
    background-color: #80a3db;
  }
