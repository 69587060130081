/* Header styles */
header {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 35px 35px 0;
    animation: .5s fadein 0.5s forwards;
    opacity: 0;
    color: #555;
    
    z-index: 1;
  }
  
  @keyframes fadein {
    100% {
        opacity: 1;
    }
  }

  .header-group {
    font: bold 16px "Open Sans", sans-serif ;
    text-decoration: none;
    color: #555;
  }
  .header-text{
    cursor: pointer;
    &:hover{
        opacity: 0.75;
    }
  }
    .header-text-bold {
      cursor: pointer;
      color: navy;
    }
  

  .header-sitename {
    cursor: auto;
    font-size: 1.25em;
  }
  

  @media (max-width: 800px) {
    header {
        padding: 20px 30px;
       
    }
    .header-text {
      font: bold 12px "Open Sans", sans-serif;
    }
  }
