.about {
    background-color: rgb(206, 222, 236);
    padding: 100px 35px;
}
.about-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1.0;
    background-size: cover;
    z-index: -1;
    background-color: #80a3db;
  }

  .about-container {
    position: relative;
    justify-content: center;
    text-align: center;
    padding-bottom: 10px;
    opacity: 1;
  }

  .about-span {
    line-height: 1.8;
  }