// Section
section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 80px;
  }
  section h3.title {
    text-transform: capitalize;
    font: bold 32px "Open Sans", sans-serif;
    margin-bottom: 30px;
    text-align: center;
  }
  
  section p {
    max-width: 775px;
    line-height: 2;
    padding: 0 20px;
    margin-bottom: 30px;
    text-align: center;
  }
  
  @media (max-width: 800px) {
    section {
        padding: 50px 20px;
    }
  }

  //Hero 
.hero {
    position: relative;
    justify-content: center;
  
    text-align: center;
    min-height: 100vh;
  }
  .name {
  justify-content: center;
       
  }
  
  .hero .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: -1;
    background-color: #80a3db;
  }
  .hero h1 {
    font: bold 52px "Open Sans", sans-serif ;
    margin-bottom: 15px;
  }
  
  .hero h3 {
    font: normal 24px "Open Sans", sans-serif ;
    margin-bottom: 5px;
  }
  
  .hero a.btn {
    padding: 20px;
  }
  
  @keyframes slidefade {
    100% {
        opacity: 1;
        margin: 0;
    }
  }
  
  @media (max-width: 800px) {
   
    .hero {
        min-height: 600px;
    }
    .hero h1 {
        font-size: 48px;
    }
    .hero h3 {
        font-size: 24px;
    }
    .hero a.btn {
        padding: 15px 40px;
    }
  }