.viewer {
    background-color: rgb(206, 222, 236);
    padding: 100px 35px 0;
}

.viewer-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
   // background-color: #80a3db;
  }

.gallery-container {
 
    position: relative;
    justify-content: center;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 100px;
    opacity: 1;
  }

  .frame-size {
      width: 90%;
      height:  700px;
  }

  @media (max-width: 800px) {
      .frame-size{
        width: 90%;
        height: 500px;
      }
    }

  .back-link {
    padding-left: 5%;
    position: relative;
    width: 90%;
    justify-content: center;
    text-align: left;
    padding-bottom: 10px;
    font-size: small;
    font-weight: bolder;
    cursor: pointer;
  }