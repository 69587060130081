/* Header styles */
.social {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px ;
    animation: .5s fadein 0.5s forwards;
    opacity: 0;
    color: #555;
    z-index: 1;
  }

  .social-icon {
      padding-right: 10px;
      text-decoration: none;
      color: #555;
      opacity: 0.75;
      &:hover{
          opacity: 1;
      }
      .end {
          padding-right: 0px;
      }
  }
  
 

  @keyframes fadein {
    100% {
        opacity: 1;
    }
  }
  
 